import { webServiceApi, engineServiceApi } from '../service';
import store from '@/vuex/store';
const api_url = store.state.user.api_url;
export default {
  // 搜索
  welcomeSearch(params) {
    return webServiceApi.post(`${api_url}/Welcome/search`, params);
  },

  welcomeGet(params) {
    return webServiceApi.post(`${api_url}/Welcome/get`, params);
  },

  welcomeAdd(params) {
    return webServiceApi.post(`${api_url}/Welcome/add`, params);
  },

  // 登录
  login(params, config) {
    return webServiceApi.post('/index.php/User/login', params, config);
  },

  // 获取验证码
  sendVerifyCode(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/sendVerifyCode',
      params,
      config
    );
  },

  // 注册
  register(params) {
    return webServiceApi.post('/index.php/User/reg', params);
  },

  // get请求示例
  getExample(params) {
    return webServiceApi.get('/xxxx/xxx', { params });
  },
};
