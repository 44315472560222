<template>
  <a-layout class="rays-tuner-login">
    <a-layout-content>
      <div class="login">
        <a-form 
          layout="inline"
          ref="formRef"
          :rules="rules" 
          :model="formState" 
        >
          <a-form-item  class="logo">
            raystuner渲染自动化
          </a-form-item>
          <a-form-item  class="head">
            登录
          </a-form-item>
          <a-form-item  class="info">
            <!-- 还没有账号？
            <router-link to="/reg" class="to-reg">
              立即注册
            </router-link> -->
          </a-form-item>
          <a-form-item class="email" name="username">
            <a-input
              v-model:value="formState.username"
              placeholder="请输入邮箱"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="passwd" name="password">
            <a-input
              v-model:value="formState.password"
              type="password"
              autocomplete="off"
              placeholder="请输入密码"
              @pressEnter="onLogin"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="login-btn">
            <a-button
              @click="onLogin"
              :class="{ onedit: btnActive }"
              :loading="btnLoading"
              >登录</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a href="https://beian.miit.gov.cn/" target="_blank" class="login_police"><i></i>浙ICP备2021039796号-1</a>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue';
import { setUser } from '@/models/tunerStorage';
import { message } from 'ant-design-vue';
import router from '@/routes';
import api from '@/api/home';

export default defineComponent({
  name: 'Login',
  // inject:["reload"],
  data() {
    return {
      user_info: [],
    };
  },
  methods: {
    logout() {
      //  storage.remove("user_info");
      //  this.reload();
    },
  },
  setup() {
    const btnActive = ref(false);
    const btnLoading = ref(false);
    const formRef = ref();
    const formState = reactive({
      username: '',
      password: '',
    });

    let validateEdit = async (rule, value) => {
      // console.log('validateEdit', value ,value === '');
      if (value === '') {
        btnActive.value = false;
      } else {
        btnActive.value = true;
      }
      return Promise.resolve();
    };

    const rules = {
      username: [
        {
          required: true,
          message: '输入的邮箱地址不能为空',
          trigger: ['change', 'blur'],
        },
        // {required: true, message: '输入的邮箱地址不能为空', trigger: 'change'},
        {
          type: 'email',
          message: '请输入有效的邮箱地址',
          trigger: ['change', 'blur'],
        },
        // {type: 'email', message: '请输入有效的邮箱地址', trigger: 'blur'},
        { validator: validateEdit, trigger: 'change' },
      ],
      password: [
        {
          required: true,
          message: '输入的密码不能为空',
          trigger: ['change', 'blur'],
        },
        // {required: true, message: '输入的密码不能为空', trigger: 'change'},
        { validator: validateEdit, trigger: 'change' },
      ],
    };
    /**
     * 用户登录
     * @method onLogin
     */
    let onLogin = () => {
      formRef.value
        .validate()
        .then(async () => {
          btnLoading.value = true;
          // console.log('onLogin', formState);
          const params = {
            username: formState.username,
            password: formState.password,
          };
          const res = await api.login(params, { noMessage: true });
          btnLoading.value = false;
          if (!res) {
            message.error('登录失败!');
            return;
          }
          try {
            const { data, code, msg } = res;
            if (code == 0) {
              const _userinfo = data;
              _userinfo.uid = parseInt(_userinfo.uid);
              setUser(_userinfo);
              //JS路由跳转
              router.replace({
                path: '/mainhome/simplifyhome',
              });
            } else {
              message.error(msg);
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          console.log('input error');
        });
    };
    return {
      btnLoading,
      btnActive,
      formRef,
      formState,
      rules,
      onLogin,
    };
  },
  components: {},
  mounted() {
    //  this.user_info=storage.get("user_info");
    // console.log(this.user_info);
  },
});
</script>

<style lang="scss" scoped>
.rays-tuner-login {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: #eeeeee;
}
.login {
  width: 400px;
  height: 560px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 96px;
  padding: 32px 40px 164px 40px;
  text-align: center;
  .ant-form-item {
    margin-right: 0;
    margin-bottom: 0;
    // font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
  }
  .logo {
    // width: 148px;
    // height: 24px;
    // background: url('~@/assets/raysengine.png') no-repeat;
    // background-size: 100% 100%;
    width: 166px;
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    font-family: "微软雅黑";
  }
  .head {
    width: 100%;
    margin-top: 48px;
    text-align: center;
    font-size: 30px;

    color: #3c3c3c;
    line-height: 38px;
  }
  .info {
    margin-top: 24px;
    height: 16px;
    width: 320px;
    text-align: center;
    font-size: 14px;
    color: #8c8c8c;
    line-height: 22px;
    .to-reg {
      // font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      color: #8877ff;
    }
  }
  .email {
    width: 320px;
    height: 60px;
    margin-top: 32px;
    input {
      width: 320px;
      height: 40px;
      border-radius: 4px;
    }
    input:hover {
      border-color: #8877ff;
    }
    input:focus {
      border-color: #8877ff;
      box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
    }
  }
  .passwd {
    width: 320px;
    height: 60px;
    margin-top: 4px;
    input {
      width: 320px;
      height: 40px;
      border-radius: 4px;
    }
    input:hover {
      border-color: #8877ff;
    }
    input:focus {
      border-color: #8877ff;
      box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
    }
  }
  .login-btn {
    width: 320px;
    height: 48px;
    margin-top: 4px;
    button {
      width: 320px;
      height: 48px;
      border-radius: 4px;
      font-size: 16px;
      // font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1px;
      color: #fff;
      border-color: #d8d8d8;
      background: #d8d8d8;
    }
    .onedit {
      border-color: #604bdc;
      background: #604bdc;
    }
  }
}
.login_police {
  height: 16px;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  margin-top: 30px;
  color: #999;
  text-decoration: underline;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  i {
      display: inline-block;
      width: 14px;
      height: 16px;
      margin-right: 6px;
      vertical-align: -2px;
      background: url('~@/assets/icon_police.png') 0 0 no-repeat;
    }
}
</style>
<style>
.rays-tuner-login .ant-form-item .ant-form-explain {
  height: 20px;
  font-size: 12px;
  text-align: LEFT;
  color: #ff2222;
  line-height: 20px;
}
</style>
